import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ManageWorkflowsService } from '../../services/manageWorkflows.service';

@Component({
    selector: 'app-manage-workflows',
    templateUrl: './manage-workflows.component.html',
    styleUrls: ['./manage-workflows.component.css']
})
export class ManageWorkflowsComponent implements OnInit {

    flowsExist: boolean = false;
    isDocLibLocked: boolean = false;
    isLoading: boolean = false;
    isValid: boolean = false;
    masterCommunityId: number;
    siteExists: boolean = false;

    constructor(private confirmationService: ConfirmationService, private manageWorkflowsService: ManageWorkflowsService, private messageService: MessageService) { }

    ngOnInit(): void {
        this.masterCommunityId = null;
    }

    onSubmit(): void {
        this.isLoading = true;
        if (this.isValid) {
            this.isValid = false;
            this.manageWorkflowsService.getSharePointSiteInformation(this.masterCommunityId).subscribe((result) => {
                this.isLoading = false;
                this.flowsExist = result.flowsExist;
                this.siteExists = result.sitesExist;
                this.isDocLibLocked = result.siteLocked;
                if (this.siteExists && this.flowsExist && !this.isDocLibLocked) {
                    alert("SharePoint Site and Power Automate workflows exist");
                    this.resetCommunityId();
                }
                else if (this.siteExists && !this.flowsExist && !this.isDocLibLocked) {
                    this.confirmAddFlows(this.masterCommunityId);
                }
                else if (this.siteExists && this.isDocLibLocked) {
                    alert("SharePoint site is locked down for approval or is archived.");
                    this.resetCommunityId();
                }
                else {
                    alert("SharePoint site with the entered master community ID does not exist");
                    this.resetCommunityId();
                }

            }, (error: any) => {
                this.isLoading = false;
                console.error(error);
            });
        }
        else {
            this.isLoading = false;
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please enter a valid Master Community ID.' });
        }
    }

    confirmAddFlows(masterCommunityId: number): void {
        if (confirm("SharePoint site exists but power automate flows do not exist. Do you want to add the flows to the existing site? Please confirm to proceed.")) {
            this.isLoading = true;
            this.isValid = false;
            this.messageService.add({ severity: 'info', summary: 'Info', detail: 'Adding Power Automate flows to the existing SharePoint site. Please wait...' });
            this.manageWorkflowsService.addPowerAutomateFlows(masterCommunityId).subscribe((result: any) => {
                if (result) {
                    this.isLoading = false;
                    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Power Automate flows added successfully' });
                }
                else {
                    this.isLoading = false;
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to add Power Automate flows' });
                }
                this.resetCommunityId();
            }, err => {
                console.log(err);
            });
        }
        else {
            this.isLoading = false;
            this.messageService.add({ severity: 'info', summary: 'Info', detail: 'Canceled' });
            this.resetCommunityId();
        }
    }

    onMasterCommunityChange(masterCommunityId): void {
        this.masterCommunityId = masterCommunityId;
        this.isValid = /^[0-9]+$/.test(this.masterCommunityId.toString());
    }

    resetCommunityId(): void {
        this.masterCommunityId = null;
        this.isValid = false;
    }
}
