<p-toast position="top-right"></p-toast>

<div class="well panel">
    <h2> <i class="fa fa-map fa-lg"></i> Add Workflows</h2>
    <br>
    <div class="row">
        <div class="form-group col-sm-12">
            <label class="control-label"> Master Community ID : </label>
            <input type="text" class="form-control" id="master-communityId" [(ngModel)]="masterCommunityId"
                placeholder="Enter Master Community ID" maxlength="50"
                (ngModelChange)="onMasterCommunityChange(masterCommunityId)" (keydown.enter)="onSubmit()">
        </div>
        <div class="form-group col-sm-12">
            <button [disabled]="!isValid" type="button" class="btn btn-primary" (click)="onSubmit()">
                <i class="fa fa-send-o"></i>&nbsp; Submit
            </button>
            <img src="../../assets/loading_spinner-alternate.gif" *ngIf="isLoading" class="smaller-loader"
                alt=" Loading...">
        </div>
    </div>
</div>