import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MessageService } from 'primeng/api';
import { PanelModule } from 'primeng/panel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { OAuthModule } from 'angular-oauth2-oidc';

import { AddOrgComponent, EditOrgComponent, JobQueueManagerComponent, JobStatsComponent, JobHistoryComponent, ManageWorkflowsComponent, OrgManagerComponent, SiteBannerManagerComponent } from './admin';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './authGuard';
import { CreatebookComponent } from './createbook/createbook.component';
import { CreatesiteComponent } from './createsite/createsite.component';
import { JobQueueListComponent, SiteBannerComponent, ToolbarComponent } from './shared';
import { LoginComponent } from './login/login.component';
import { NavigationComponent } from './navigation/navigation.component';
import { RefreshlinksComponent } from './refreshlinks/refreshlinks.component';
import { ReportsComponent } from './reports/reports.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';

import { TimerPipe } from './shared/pipes';

import { AnaplanService, AuthorizationService, JobStatsService, JobQueueService, OrgService, ReportService, SharedService, SiteService, SiteBannerService } from './services';
import { ManageWorkflowsService } from './services/manageWorkflows.service';
import { environment } from '../environments/environment';

@NgModule({
    declarations: [
        AppComponent,
        AddOrgComponent,
        CreatebookComponent,
        CreatesiteComponent,
        EditOrgComponent,
        JobQueueListComponent,
        JobQueueManagerComponent,
        JobStatsComponent,
        JobHistoryComponent,
        LoginComponent,
        NavigationComponent,
        ManageWorkflowsComponent,
        OrgManagerComponent,
        RefreshlinksComponent,
        ReportsComponent,
        SiteBannerComponent,
        SiteBannerManagerComponent,
        TimerPipe,
        ToolbarComponent,
        UnauthorizedComponent
    ],
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        CommonModule,
        ConfirmDialogModule,
        DialogModule,
        FormsModule,
        HttpClientModule,
        MessageModule,
        MessagesModule,
        PanelModule,
        RadioButtonModule,
        TableModule,
        ToastModule,
        NgMultiSelectDropDownModule.forRoot(),
        PowerBIEmbedModule,
        OAuthModule.forRoot({
            resourceServer: {
                allowedUrls: [environment.baseUrl],
                sendAccessToken: true
            }
        })
    ],
    providers: [
        AnaplanService,
        AuthGuard,
        AuthorizationService,
        ConfirmationService,
        JobQueueService,
        JobStatsService,
        MessageService,
        ManageWorkflowsService,
        OrgService,
        ReportService,
        SharedService,
        SiteService,
        SiteBannerService,
        TimerPipe
    ],
    exports: [
        TimerPipe
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }
